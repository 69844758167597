import React, { useState, useEffect } from 'react';

import '../../../styling/genieModal.css'; // Ensure this CSS file is imported

const CustomModalEffect = ({
  isOpen,
  onClose,
  children,
  width = '600px',
  height = '200px',
  className = '', // Allow extra styling if needed
}) => {
  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 800); // Delay removal for animation
    }
  }, [isOpen]);

  if (!visible) return null; // Prevents rendering when closed

  return (
    <div
      className={`genie-modal-overlay ${isOpen ? 'show' : ''} ${className}`}
      onClick={onClose}
    >
      <div
        className={`genie-modal-content ${isOpen ? 'genie-in' : 'genie-out'}`}
        onClick={(e) => e.stopPropagation()}
        style={{ width, height }} // Directly apply styles
      >
        {children}
        <button className="close-btn" onClick={onClose}>
          ✖
        </button>
      </div>
    </div>
  );
};

export default CustomModalEffect;
