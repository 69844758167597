import React, { Component } from 'react';
import Select from 'react-select';
import { Async } from 'react-select';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  FormText,
  FormFeedback,
} from 'reactstrap';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import moment from 'moment';
import {
  getUserByPhone,
  getReimbursementProcedures,
  getDependentsForUser,
  addReimbursementRequest,
} from '../../services/reimbursementClaims';
import { toast } from 'react-toastify';
import { el } from 'date-fns/locale';

// import { useLink } from 'valueLink';
// import { Input } from 'linked-controls';

class CreateClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: props.modalVisible || false,
      userPhone: '',
      userId: null,
      userName: null,
      reimbursementAllowed: false,
      claimAmount: '',
      showPrescriptionFileInput: false,
      showInvoiceFileInput: false,
      showMerFileInput: false,
      showLabInvoiceFileInput: false,
      showMedicineInvoiceFileInput: false,
      proceduresList: [],
      relativeResult: [],
      relativeLoading: false,
      procedureCapping: 0,
      selectedProcedure: null,
      selectedRelative: null,
      dateKey: null,
      submitting: false,
      newPolicyUser: false,
    };
  }

  componentWillReceiveProps(newProps) {
    const { modalVisible } = this.state;
    console.log(this.state, newProps);
    if (newProps.modalVisible !== modalVisible) {
      this.setState({
        modalVisible: newProps.modalVisible,
      });
    }
  }

  toggle = () => {
    const { submitting } = this.state;
    const { updateModalVisible } = this.props;
    if (submitting) {
      return;
    }
    updateModalVisible(!this.state.modalVisible);
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  };

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  getUserDetails = (phone) => {
    const { userPhone, dateKey } = this.state;
    console.log(phone, userPhone);
    this.setState({
      relativeLoading: true,
    });
    getUserByPhone(phone || userPhone)
      .then((result) => {
        if (result.message === 'success') {
          this.setState({
            userId: result.result.userId,
            userName: result.result.name,
            reimbursementAllowed: result.result.reimbursement === 1,
          });
          return Promise.all([
            getReimbursementProcedures(
              result.result.userId,
              dateKey ? dateKey : ''
            ),
            getDependentsForUser(result.result.userId),
          ]);
        } else {
          const error = new Error('Invalid response');
          error.errorMessage = result.errorMessage;
          throw error;
        }
      })
      .then((result) => {
        const proceduresResult = result[0];
        const dependentsResult = result[1];
        if (proceduresResult.message === 'success') {
          this.setState({
            proceduresList: proceduresResult.result
              .filter((ele) => !ele.procedureName.startsWith('prebooked'))
              .map((ele) => {
                return {
                  value: ele.procedureId,
                  label: ele.title,
                  metaData: ele,
                };
              }),
          });
        }
        if (dependentsResult.message === 'success') {
          this.setState({
            newPolicyUser: dependentsResult.result.newPolicyUser,
            relativeResult: dependentsResult.result.dependents.map((ele) => ({
              value: ele.dependentId,
              label: `${ele.name}(${ele.relation})`,
              metaData: ele,
            })),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          relativeLoading: false,
        });
      });
  };

  onUserIdInputBlur = () => {
    this.getUserDetails();
  };

  handleDateChange = (event) => {
    const date = event.target.value;
    if (!date) {
      return;
    }
    const dateKey = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    this.setState({ dateKey });
    this.getUserDetails();
  };

  onRelativeSelected = (ele, action) => {
    if (action.action === 'select-option' || action.action === 'set-value') {
      this.setState({
        selectedRelative: ele.value,
      });
    }
  };

  onProcedureSelected = (ele, action) => {
    if (action.action === 'select-option' || action.action === 'set-value') {
      this.setState({
        showPrescriptionFileInput: ele.metaData.prescriptionRequired,
        showInvoiceFileInput: ele.metaData.invoiceRequired,
        showMerFileInput: ele.metaData.merRequired,
        showLabInvoiceFileInput:
          ele.metaData.procedureName === 'emergency-consult' ||
          ele.metaData.procedureName === 'reimburse-consult' ||
          ele.metaData.prescriptionRequired,
        showMedicineInvoiceFileInput:
          ele.metaData.procedureName === 'emergency-consult' ||
          ele.metaData.procedureName === 'reimburse-consult',
        procedureCapping: ele.metaData.maxCap,
        selectedProcedure: ele.value,
      });
    }
  };

  placeRequest = () => {
    const {
      userName,
      userId,
      userPhone,
      claimAmount,
      selectedProcedure,
      selectedRelative,
      dateKey,
      newPolicyUser,
    } = this.state;
    // Get the files uploaded
    const formElements = document.forms[0].elements;
    const invoiceFile = formElements['invoiceFile'];
    const prescriptionFile = formElements['prescriptionFile'];
    const merFile = formElements['merFile'];
    const labInvoiceFile = formElements['labReport'];
    const medicineInvoiceFile = formElements['medicineInvoiceFile'];
    const covidVaccine = this.state.proceduresList.filter(
      (procedure) =>
        procedure.metaData.procedureName === 'covid-vaccine' &&
        procedure.metaData.procedureId === this.state.selectedProcedure
    );
    if (covidVaccine.length) {
      if (
        !userId ||
        !claimAmount ||
        !selectedProcedure ||
        !dateKey ||
        invoiceFile.files.length === 0
      ) {
        toast.error('Please complete all fields before submitting the request');
        return;
      }
    } else if (
      !userId ||
      !claimAmount ||
      !selectedProcedure ||
      !dateKey ||
      invoiceFile.files.length === 0 ||
      (this.state.showPrescriptionFileInput
        ? prescriptionFile.files.length === 0
        : false)
    ) {
      toast.error('Please complete all fields before submitting the request');
      return;
    }
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('newPolicyUser', newPolicyUser);
    if (selectedRelative) {
      formData.append('relativeId', selectedRelative);
    }
    formData.append('procedureId', selectedProcedure);
    formData.append('amount', claimAmount);
    formData.append('date', dateKey);
    if (prescriptionFile) {
      for (let i = 0; i < prescriptionFile.files.length; i++) {
        formData.append('prescriptionFile', prescriptionFile.files[i]);
      }
    }
    for (let i = 0; i < invoiceFile.files.length; i++) {
      formData.append('invoiceFile', invoiceFile.files[i]);
    }
    if (merFile) {
      for (let i = 0; i < merFile.files.length; i++) {
        formData.append('merFile', merFile.files[i]);
      }
    }
    if (labInvoiceFile) {
      for (let i = 0; i < labInvoiceFile.files.length; i++) {
        formData.append('labReport', labInvoiceFile.files[i]);
      }
    }
    if (medicineInvoiceFile) {
      for (let i = 0; i < medicineInvoiceFile.files.length; i++) {
        formData.append('medicineInvoice', medicineInvoiceFile.files[i]);
      }
    }
    this.setState({
      submitting: true,
    });
    addReimbursementRequest(formData)
      .then((result) => {
        console.log(result);
        if (result.message === 'success') {
          toast.success(
            `Claim submitted successfully! Claim Id- ${result.result.requestId}`
          );
          this.props.updateModalVisible(!this.state.modalVisible);
          this.setState({
            modalVisible: false,
          });
        } else {
          toast.error(
            result.errorMessage ||
              'Something went wrong. Please try again later'
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err.errorMessage || 'Something went wrong. Please try again later'
        );
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  };

  handleInputChange = (e) => {
    const value = e.target.value;
    // Check if the input consists of only digits and is a positive number
    if (/^[1-9]\d*$/.test(value) || value === '') {
      this.setState({ claimAmount: value });
    }
  };

  render() {
    const {
      relativeResult,
      relativeLoading,
      modalVisible,
      showPrescriptionFileInput,
      showInvoiceFileInput,
      showMerFileInput,
      showLabInvoiceFileInput,
      showMedicineInvoiceFileInput,
      proceduresList,
      procedureCapping,
      userName,
      reimbursementAllowed,
      submitting,
    } = this.state;
    const userPhoneLink = Link.state(this, 'userPhone').onChange((ele) => {
      if (ele.length === 10) {
        this.getUserDetails(ele);
      }
    });
    // const claimAmountLink = Link.state(this, 'claimAmount');
    return (
      <Modal
        isOpen={modalVisible}
        toggle={this.toggle}
        className={this.props.className}
      >
        <ModalHeader
          className="bg-[#714FFF] text-gray-100"
          toggle={this.toggle}
        >
          <p style={{ color: 'white' }}>Create Claim</p>
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <Row>
                <Col className="col-3">
                  <Label>Enter User Phone</Label>
                </Col>
                <Col>
                  <Input
                    className={`form-control ${
                      userName && !reimbursementAllowed ? 'is-invalid' : ''
                    }`}
                    valueLink={userPhoneLink}
                    type="number"
                    maxLength={10}
                    onBlur={this.onUserPhoneInputBlur}
                  />
                  {userName && <FormText>{userName}</FormText>}
                  {!reimbursementAllowed && (
                    <FormFeedback>
                      Reimbursements are not allowed for this user
                    </FormFeedback>
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col className="col-3">
                  <Label>Date:</Label>
                </Col>
                <Col>
                  <input
                    className="form-control"
                    type="date"
                    onChange={this.handleDateChange}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col className="col-3">
                  <Label>Relative</Label>
                </Col>
                <Col>
                  <Select
                    isSearchable={false}
                    defaultValue={
                      relativeResult.length > 0 && relativeResult[0]
                    }
                    options={relativeResult}
                    isLoading={relativeLoading}
                    onChange={this.onRelativeSelected}
                  />
                  <FormText>
                    Leave this blank if the reimbursement is for the user itself
                  </FormText>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col className="col-3">
                  <Label>Reimbursement for</Label>
                </Col>
                <Col>
                  <Select
                    isSearchable={false}
                    defaultValue={
                      proceduresList.length > 0 && proceduresList[0]
                    }
                    options={proceduresList}
                    isLoading={relativeLoading}
                    onChange={this.onProcedureSelected}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col className="col-3">
                  <Label>Amount in INR</Label>
                </Col>
                <Col>
                  <input
                    className="form-control"
                    value={this.state.claimAmount}
                    onChange={(e) => this.handleInputChange(e)}
                  />
                  <FormText>{`Capping: ${procedureCapping}`}</FormText>
                </Col>
              </Row>
            </FormGroup>

            {showPrescriptionFileInput && (
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label for="prescriptionFile">Prescription File</Label>
                  </Col>
                  <Col>
                    <input
                      type="file"
                      multiple
                      name="prescriptionFile"
                      id="prescriptionFile"
                    />
                  </Col>
                </Row>
              </FormGroup>
            )}
            {showInvoiceFileInput && (
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label for="invoiceFile">Invoice File</Label>
                  </Col>
                  <Col>
                    <input
                      type="file"
                      multiple
                      name="invoiceFile"
                      id="invoiceFile"
                    />
                  </Col>
                </Row>
              </FormGroup>
            )}
            {showMerFileInput && (
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label for="merFile">MER File</Label>
                  </Col>
                  <Col>
                    <input type="file" multiple name="merFile" id="merFile" />
                  </Col>
                </Row>
              </FormGroup>
            )}
            {showLabInvoiceFileInput && (
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label for="labReport">Lab Invoice File</Label>
                  </Col>
                  <Col>
                    <input
                      type="file"
                      multiple
                      name="labReport"
                      id="labReport"
                    />
                  </Col>
                </Row>
              </FormGroup>
            )}
            {showMedicineInvoiceFileInput && (
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label for="medicineInvoiceFile">
                      Medicine Invoice File
                    </Label>
                  </Col>
                  <Col>
                    <input
                      type="file"
                      multiple
                      name="medicineInvoiceFile"
                      id="medicineInvoiceFile"
                    />
                  </Col>
                </Row>
              </FormGroup>
            )}
          </form>
          {/* <Alert
            color="warning"
            isOpen={this.state.showFormWarning}
            toggle={this.onAlertDismiss}
          >
            {this.state.alertMessage}
          </Alert> */}
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ backgroundColor: '#714FFF' }}
            onClick={this.placeRequest}
            disabled={relativeLoading || !reimbursementAllowed || submitting}
          >
            {submitting ? 'Submitting request...' : 'Place Request'}
          </Button>
          <Button
            color="secondary"
            onClick={this.toggle}
            disabled={submitting}
            style={{ color: 'black' }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CreateClaim;
