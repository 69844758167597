import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import styled from 'styled-components';
import moment from 'moment';
import { Table, Button } from 'antd';
import { downloadArrayOfObjectsAsCSV } from '../../components/common/commonFunctions';

const tableColumn = [
  {
    title: 'RID',
    dataIndex: 'RID',
    render: (value, row, index) => {
      return (
        <a
          href={`/reimburse-claim/${value}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#1890ff' }}
        >
          {value}
        </a>
      );
    },
  },
  {
    title: 'RI type',
    dataIndex: 'RIType',
    render: (value, row, index) => {
      return <p>{value}</p>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (value, row, index) => {
      return <p>{value}</p>;
    },
  },
  {
    title: 'Approved Amount',
    dataIndex: 'approvedAmount',
    render: (value, row, index) => {
      return <p>{value}</p>;
    },
  },

  {
    title: 'RIDType',
    dataIndex: 'RIDType',
    render: (value, row, index) => {
      return <p>{value}</p>;
    },
  },
];

export default function CopyClaims({
  isOpen,
  copyClaimData,
  onCloseCopyClaimsModal,
}) {
  const tableData = copyClaimData;
  return (
    <Modal
      isOpen={isOpen}
      toggle={onCloseCopyClaimsModal}
      size="lg"
      style={{ maxWidth: '700px', width: '100%', borderRadius: '6px' }}
    >
      <ModalHeader toggle={onCloseCopyClaimsModal}>
        <div className="flex flex-row justify-between items-center">
          <h1 style={{ fontSize: '25px', fontWeight: 600 }}>Copy claims</h1>
          <Button
            className="custom-hover-button mr-4"
            style={{ border: '1px solid #714EFF', fontWeight: 500 }}
            onClick={() =>
              downloadArrayOfObjectsAsCSV(
                tableData,
                `Copy_Claims_${moment().format('YYYY-MM-DD')}`
              )
            }
          >
            Download Csv
          </Button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <Table
            columns={tableColumn}
            dataSource={tableData}
            pagination={true}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}
