import React from 'react';
import NoInternetImg from '../../assets/images/disconnect.png';
import styled from 'styled-components';

const LogoContainer = styled.img`
  max-width: 30rem;
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  flex-direction: column; /* Stack children vertically */
  text-align: center; /* Center-align text */
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const title = {
  'font-size': '18px',
  color: '#714FFF',
  'font-family': 'Montserrat',
  'font-weight': 'bold',
};
export default function NoInternet() {
  return (
    <>
      <PageContainer>
        <ContentContainer>
          <LogoContainer src={NoInternetImg} alt="view claim" />

          <h2 className="mt-2" style={title}>
            Oops! It seems you're offline.
          </h2>
          <p
            className="mt-1"
            style={{ color: '#714FFF', fontSize: '15px', fontWeight: '600' }}
          >
            We’re having trouble connecting to the internet. Please check your
            connection and try again.
          </p>
        </ContentContainer>
      </PageContainer>
    </>
  );
}
